import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ImageSceIcon4 from "../../assets/img/icon/sve-icon4.png";
import ImageSceIcon5 from "../../assets/img/icon/sve-icon5.png";
import ImageSceIcon6 from "../../assets/img/icon/sve-icon6.png";
import BgSlider00 from "../../assets/img/sba/etudiants-divers_sombre.jpg";
import BgSlider01 from "../../assets/img/slider/slider_bg_01_sombre.jpg";
import BgSlider02 from "../../assets/img/slider/slider_bg_02_sombre.jpg";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
        <button type="button" className={ `slick-next ${className}`} style={{ ...style }} onClick={onClick} ><i className="far fa-angle-right"></i></button>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
    <button type="button" className={ `slick-prev ${className}`} style={{ ...style }} onClick={onClick} ><i className="far fa-angle-left"></i></button>
      
    );
}

function Sliderone() {
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    fade: true,
    arrows: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      { breakpoint: 1200, settings: { dots: false, arrows: false } },
    ],
  };

  return (
    <>
      <section id="home" className="slider-area fix p-relative">
        <Slider
          className="slider-active"
          style={{ background: "#141b22" }}
          {...settings}
        >
          <div>
            <div className="single-slider slider-bg"  style={{ backgroundImage: `url(${BgSlider00})` , backgroundSize: "cover" }} >
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-8">
                    <div className="slider-content s-slider-content mt-130">
                      <h5 data-animation="fadeInUp" data-delay=".4s">
                        Skills Bridge Academy
                      </h5>
                      <h2 data-animation="fadeInUp" data-delay=".4s">
                        Nous développons des compétences adaptées à la réalité du terrain
                      </h2>
                      <p className="hide" data-animation="fadeInUp" data-delay=".6s">
                        Notre force réside dans une ingénierie pédagogique
                        innovante, spécialement conçue pour répondre aux défis
                        complexes.
                      </p>
                      <div className="slider-btn mt-30">
                        <Link
                          to="/candidature"
                          className="btn ss-btn mr-15"
                          data-animation="fadeInLeft"
                          data-delay=".4s"
                        >
                          S'inscrire <i className="fal fa-long-arrow-right" />
                        </Link>
                        <Link
                          to="/about"
                          className="btn ss-btn active"
                          data-animation="fadeInLeft"
                          data-delay=".4s"
                        >
                          Découvrir l'école
                          <i className="fal fa-long-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 p-relative"></div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="single-slider slider-bg"  style={{ backgroundImage: `url(${BgSlider01})` , backgroundSize: "cover" }} >
                    <div className="container">
                        <div className="row">
                  <div className="col-lg-8 col-md-8">
                    <div className="slider-content s-slider-content mt-130">
                      <h5 data-animation="fadeInUp" data-delay=".4s">
                        Mobilité internationale
                      </h5>
                      <h2 data-animation="fadeInUp" data-delay=".4s">
                        Récompenses des meilleurs étudiants et un séjour professionnel à l'international
                      </h2>
                      <p data-animation="fadeInUp" data-delay=".6s">
                        Notre programme de mobilité internationale s’articule autour de deux axes : la récompenses des meilleurs de nos étudiants sur chaque parcours et un séjour professionnel après l’obtention des diplômes.
                      </p>
                      <div className="slider-btn mt-30">
                        <Link
                          to="/mobilite-internationale"
                          className="btn ss-btn mr-15"
                          data-animation="fadeInLeft"
                          data-delay=".4s"
                        >
                          En savoir plus <i className="fal fa-long-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 p-relative"></div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className="single-slider slider-bg"
              style={{
                backgroundImage: `url(${BgSlider02})`,
                backgroundSize: "cover",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-8">
                    <div className="slider-content s-slider-content mt-130">
                      <h5 data-animation="fadeInUp" data-delay=".4s">
                        Engagement social
                      </h5>
                      <h2 data-animation="fadeInUp" data-delay=".4s">
                        Programme Social de Financement de Bourses d’Études
                      </h2>
                      <p data-animation="fadeInUp" data-delay=".6s">
                        PROSFIBE souligne notre engagement Social et durable en faveur des étudiants ayant obtenu des meilleurs résultats au baccalauréat mais issus de familles modestes en incapacité partielle ou totale de prendre en charge les études supérieures de leurs enfants.
                      </p>
                      <div className="slider-btn mt-30">
                        <Link
                          to="/prosfibe"
                          className="btn ss-btn mr-15"
                          data-animation="fadeInLeft"
                          data-delay=".4s"
                        >
                          Découvrir <i className="fal fa-long-arrow-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 p-relative"></div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </section>

      <section className="service-details-two p-relative">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="services-box07">
                <div className="sr-contner">
                  <div className="icon">
                    <img src={ImageSceIcon4} alt="icon04" />
                  </div>
                  <div className="text">
                    <h5>
                      <Link to="/about">Notre école</Link>
                    </h5>
                    <p>
                      SKILLS BRIDGE ACADEMY est une école de commerce et de management spécialisée dans la formation des managers et cadres de direction de demain.
                    </p>
                    <Link to="/about">
                      En savoir plus <i className="fal fa-long-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="services-box07 active">
                <div className="sr-contner">
                  <div className="icon">
                    <img src={ImageSceIcon5} alt="icon05" />
                  </div>
                  <div className="text">
                    <h5>
                      <Link to="/mobilite-internationale">Mobilité internationale</Link>
                    </h5>
                    <p>
                      Notre programme de mobilité internationale s’articule autour de deux axes : la récompenses des meilleurs de nos étudiants sur chaque parcours et...
                    </p>
                    <Link to="/mobilite-internationale">
                      En savoir plus <i className="fal fa-long-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="services-box07">
                <div className="sr-contner">
                  <div className="icon">
                    <img src={ImageSceIcon6} alt="icon06" />
                  </div>
                  <div className="text">
                    <h5>
                      <Link to="/prosfibe">Programme PROSFIBE</Link>
                    </h5>
                    <p>
                      Le Programme Social de Financement de Bourses d’Etudes, souligne notre engagement Social et durable (RSE) en faveur des étudiants...
                    </p>
                    <Link to="/prosfibe">
                      En savoir plus <i className="fal fa-long-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Sliderone;
